import * as React from "react";

import { ReviewsCarousel } from "@shared-ui/retail-product-reviews-reviews-carousel";
import { ComponentProps } from "typings/flexFramework/FlexComponent";
import { Experiment, ExperimentControl, ExperimentVariant } from "@shared-ui/experiment-context";

export const ReviewsCarouselComponent: React.FC<ComponentProps> = (props) => {
  const { templateComponent } = props;
  if (!templateComponent) {
    return null;
  }

  return (
    <>
      <Experiment name="pending_reviews_carousel_component_on_homepage">
        <ExperimentControl />
        <ExperimentVariant bucket={1}>
          <ReviewsCarousel inputs={{}} />
        </ExperimentVariant>
      </Experiment>
    </>
  );
};
export default ReviewsCarouselComponent;
